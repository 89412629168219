import { Controller, UseFormReturn } from 'react-hook-form';
import {
  loadWorkspaceBotSettings,
  loadWorkspaceDeals,
  loadWorkspaceIdentities,
  loadWorkspaceUsers,
} from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import Select from '../../../components/Select';
import Textarea from '../../../components/Textarea';
import { t } from '../../../i18n';
import { required, maxLength, minLength, email } from '../../../utils/form';
import { getTrainerPokerRoomOptions, trainerGameTypeOptions } from '../../../utils/trainer';
import AccountFormLimitIds from '../../account/AccountFormLimitIds';
import AccountGeolocationDialog from '../../account/AccountGeolocationDialog';
import { tableSizeOptions } from '../../account/helpers';
import { WorkspaceAccountFields } from '../../account/types';
import { getBotSettingCaption } from '../../global-bot-setting-rule/helpers';
import { getIdentityFullName } from '../../workspace-identity/helpers';
import WorkspaceAccountFormTagIds from '../WorkspaceAccountFormTagIds';
import styles from './styles.module.scss';
import WorkspaceAccountFormChannelIds from './WorkspaceAccountFormChannelIds';
import WorkspaceAccountFormClubIds from './WorkspaceAccountFormClubIds';

export const getFields = (args: {
  form: UseFormReturn<WorkspaceAccountFields & { nickname_pool?: string }>;
  workspace: WorkspaceSchema;
  required?: boolean;
}) => {
  const { form, workspace, required: req } = args;

  const { id: workspaceId } = workspace;

  const roomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const validate = req ? { required } : {};

  const room = watch('room')?.value;

  return {
    login: (
      <Controller
        name="login"
        control={control}
        rules={{
          validate: { required, minLength: minLength(), maxLength: maxLength(), ...validate },
        }}
        render={({ field }) => (
          <Input label={`${t('common.login')}*`} error={errors.login?.message} {...field} />
        )}
      />
    ),
    password: (
      <Controller
        name="password"
        control={control}
        rules={{ validate: { required, minLength: minLength(), ...validate } }}
        render={({ field }) => (
          <PasswordInput
            label={`${t('common.password')}*`}
            error={errors.password?.message}
            {...field}
          />
        )}
      />
    ),
    room: (
      <Controller
        name="room"
        control={control}
        rules={{ validate: { required, ...validate } }}
        render={({ field }) => (
          <Select
            clearable={false}
            disabled={roomOptions.length === 1}
            label={`${t('common.poker_room')}*`}
            value={field.value}
            error={errors.room?.message}
            onChange={(value) => {
              setValue('game_types', []);
              setValue('comparative_limits', []);
              setValue('deal_ids', []);

              field.onChange(value);
            }}
            options={roomOptions}
          />
        )}
      />
    ),
    club_ids: <WorkspaceAccountFormClubIds workspaceId={workspaceId} required={req} />,
    game_types: (
      <Controller
        name="game_types"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Multi
            renderValueAsTag
            label={t('common.game_types')}
            value={field.value}
            onChange={field.onChange}
            error={errors.game_types?.message}
            options={trainerGameTypeOptions}
          />
        )}
      />
    ),
    comparative_limits: <AccountFormLimitIds required={req} />,
    table_sizes: (
      <Controller
        name="table_sizes"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Multi
            label={t('common.table_size')}
            options={tableSizeOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.table_sizes?.message}
          />
        )}
      />
    ),
    max_tables: (
      <Controller
        name="max_tables"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Input
            label={t('common.max_tables')}
            error={errors.max_tables?.message}
            format={{
              thousandSeparator: false,
              onValueChange: field.onChange,
            }}
            {...field}
          />
        )}
      />
    ),
    geo: (
      <Controller
        name="geo"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Input
            label={t('common.geolocation')}
            extra={<AccountGeolocationDialog />}
            error={errors.geo?.message}
            className={styles.geoInput}
            {...field}
          />
        )}
      />
    ),
    channel_ids: <WorkspaceAccountFormChannelIds workspaceId={workspaceId} required={req} />,
    email: (
      <Controller
        name="email"
        control={control}
        rules={{ validate: email }}
        render={({ field }) => (
          <Input label={t('common.email')} error={errors.email?.message} {...field} />
        )}
      />
    ),
    email_password: (
      <Controller
        name="email_password"
        control={control}
        render={({ field }) => (
          <PasswordInput
            label={t('common.email_password')}
            error={errors.email_password?.message}
            {...field}
          />
        )}
      />
    ),
    tag_ids: <WorkspaceAccountFormTagIds workspaceId={workspaceId} required={req} />,
    affiliate_reference_number: (
      <Controller
        name="affiliate_reference_number"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Input
            label={t('common.affiliate')}
            error={errors.affiliate_reference_number?.message}
            {...field}
          />
        )}
      />
    ),
    user_id: (
      <Controller
        name="user_id"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceUsers({ workspaceId }, params)}
            select={(item) => ({
              value: item.id,
              label: item.username,
            })}
          >
            {({ options, ...rest }) => (
              <Select
                label={t('common.assign_to')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.user_id?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    identity_id: (
      <Controller
        name="identity_id"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceIdentities({ workspaceId }, params)}
            select={(item) => ({
              value: item.id,
              label: getIdentityFullName(item),
            })}
          >
            {({ options, ...rest }) => (
              <Select
                label={t('common.identity')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.identity_id?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    bot_settings_ids: (
      <Controller
        name="bot_settings_ids"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.Async
            onLoad={async (params) => {
              const response = await loadWorkspaceBotSettings({ workspaceId }, params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: getBotSettingCaption(item),
              }));

              return options;
            }}
          >
            {({ options, ...rest }) => (
              <Select.Multi
                label={t('common.bot_settings')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.bot_settings_ids?.message}
                {...rest}
              />
            )}
          </Select.Async>
        )}
      />
    ),
    deal_ids: (
      <Controller
        name="deal_ids"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Select.AsyncV2
            onLoad={(params) =>
              loadWorkspaceDeals(
                { workspaceId },
                { ...params, ...(room && { poker_room_in: String(room) }) }
              )
            }
            select={(item) => ({
              value: item.id,
              label: item.code,
              caption: `${item.agent.name} • ${item.club.code}${item.holder ? ` • ${item.holder?.username}` : ''}`,
            })}
          >
            {({ options, ...rest }) => (
              <Select.Multi
                label={t('common.deals')}
                options={options}
                value={field.value}
                onChange={field.onChange}
                error={errors.deal_ids?.message}
                {...rest}
              />
            )}
          </Select.AsyncV2>
        )}
      />
    ),
    nickname_pool: (
      <Controller
        name="nickname_pool"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={({ field }) => (
          <Textarea
            label={{
              text: t('common.nickname_pool'),
              hint: String(t('sentences.enter_nicknames_separated_by_commas')),
            }}
            maxLength={0}
            className={styles.textarea}
            error={errors.nickname_pool?.message}
            {...field}
          />
        )}
      />
    ),
  };
};
