import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { updateWorkspaceAccountRoomConfig } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Button from '../../components/Button';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import AccountDetailRoomConfig from '../account/AccountDetailRoomConfig';
import AccountPilotButton from '../account/AccountPilotButton';
import AccountPilotView from '../account/AccountPilotView';
import AccountStatus from '../account/AccountStatus';
import { getScheduleTooltip } from '../account/helpers';
import { OnTransactionsOpen } from '../account/types';
import { useWorkspaceAccountTablesQuery } from './queries';
import WorkspaceAccountDetailBalanceTab from './WorkspaceAccountDetailBalanceTab';
import WorkspaceAccountDetailDropdown from './WorkspaceAccountDetailDropdown';
import WorkspaceAccountDetailGeneralTab from './WorkspaceAccountDetailGeneralTab';
import WorkspaceAccountDetailTablesTab from './WorkspaceAccountDetailTablesTab';
import WorkspaceAccountRoomConfigLoader from './WorkspaceAccountRoomConfigLoader';
import WorkspaceAccountStrategyProfile from './WorkspaceAccountStrategyProfile';
import WorkspaceAccountStrategyProfileLoader from './WorkspaceAccountStrategyProfile/WorkspaceAccountStrategyProfileLoader';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
  onClose: () => void;
  onNotesOpen?: () => void;
  onTransactionsOpen?: OnTransactionsOpen;
  getBotSettingRoute?: (args: { botSettingId: BotSettingSchema['id'] }) => string;
  getChannelRoute?: (args: { channelId: ChannelSchema['id'] }) => string;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getIdentityRoute?: (args: { identityId: IdentitySchema['id'] }) => string;
}

const WorkspaceAccountDetail = (props: Props) => {
  const {
    workspaceId,
    data,
    onClose,
    onNotesOpen,
    onTransactionsOpen,
    getBotSettingRoute,
    getChannelRoute,
    getDealRoute,
    getUserRoute,
    getIdentityRoute,
  } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const { id, login, comparative_limits, display_name, pid, stage, on_verification, notes_count } =
    data;

  const { data: accountTables } = useWorkspaceAccountTablesQuery({
    workspaceId,
    accountId: data.id,
    enabled: data.is_online,
  });

  const titleIcons = (
    <>
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
      {on_verification && (
        <Tooltip label={t('common.pending_verification')}>{icon('checkCircle', 12)}</Tooltip>
      )}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        copy
        title={login}
        icons={titleIcons}
        subTitle={[
          display_name && <PageAddon.Copy>{display_name}</PageAddon.Copy>,
          pid && <PageAddon.Copy>{pid}</PageAddon.Copy>,
          <PageAddon.Copy>{stage.name}</PageAddon.Copy>,
          <AccountStatus data={data} />,
        ]}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: (
              <WorkspaceAccountDetailGeneralTab
                data={data}
                workspaceId={workspaceId}
                getBotSettingRoute={getBotSettingRoute}
                getChannelRoute={getChannelRoute}
                getDealRoute={getDealRoute}
                getUserRoute={getUserRoute}
                getIdentityRoute={getIdentityRoute}
              />
            ),
          },
          {
            label: t('common.strategy'),
            content: (
              <WorkspaceAccountStrategyProfileLoader workspaceId={workspaceId} accountId={data.id}>
                {({ data: strategy }) => (
                  <WorkspaceAccountStrategyProfile
                    workspaceId={workspaceId}
                    account={data}
                    data={strategy}
                  />
                )}
              </WorkspaceAccountStrategyProfileLoader>
            ),
          },
          {
            label: t('common.config'),
            content: (
              <WorkspaceAccountRoomConfigLoader workspaceId={workspaceId} accountId={data.id}>
                {({ data: roomConfig }) => (
                  <AccountDetailRoomConfig
                    account={data}
                    data={roomConfig}
                    onUpdate={(payload) =>
                      updateWorkspaceAccountRoomConfig({
                        workspaceId,
                        accountId: data.id,
                        payload,
                      })
                    }
                  />
                )}
              </WorkspaceAccountRoomConfigLoader>
            ),
          },
          {
            label: t('common.balance'),
            content: (
              <WorkspaceAccountDetailBalanceTab
                accountData={data}
                workspaceId={workspaceId}
                onTransactionsOpen={onTransactionsOpen}
              />
            ),
          },
          {
            label: t('common.tables'),
            content: <WorkspaceAccountDetailTablesTab workspaceId={workspaceId} data={data} />,
            count: accountTables?.tables.length,
          },
        ]}
      >
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <AccountPilotButton data={data} workspaceId={workspaceId} isDetail />
            <AccountPilotView data={data} isDetail />
            <Notes.Button
              count={notes_count}
              onClick={() => navigate(routes.workspaceAccountNotes({ workspaceId, accountId: id }))}
            />
            <Tooltip
              label={getScheduleTooltip({ account: data, workspaceId }) || t('common.schedule')}
              hideOnMobile={Boolean(comparative_limits.length)}
            >
              <div>
                <Button
                  icon={icon('calendar', 16)}
                  variant="transparent"
                  onClick={() =>
                    navigate(routes.workspaceAccountSchedule({ workspaceId, accountId: id }))
                  }
                  data-testid="schedule"
                  disabled={!comparative_limits.length}
                />
              </div>
            </Tooltip>
            <WorkspaceAccountDetailDropdown
              data={data}
              workspaceId={workspaceId}
              onNotesOpen={onNotesOpen}
            />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceAccountDetail;
