import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { COLUMNS, WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import { t } from '../../i18n';
import { convertCentsToDollars } from '../../utils/data';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';
import { getGeolocationAsString, TABLE_SIZE_LABELS } from '../account/helpers';
import { WorkspaceAccountFields } from '../account/types';
import { getIdentityFullName } from '../workspace-identity/helpers';
import WorkspaceAccountColorCircle from './WorkspaceAccountColorCircle';

export const COLUMNS_LABELS: Record<WorkspaceStageSchema['columns'][number], string> = {
  [COLUMNS.UNKNOWN]: t('common.unknown'),
  [COLUMNS.CREATED_ON]: t('common.created'),
  [COLUMNS.MODIFIED_ON]: t('common.modified'),
  [COLUMNS.ROOM]: t('common.poker_room'),
  [COLUMNS.CLUB_NAME]: t('common.club_name'),
  [COLUMNS.GAME_TYPES]: t('common.game_types'),
  [COLUMNS.LIMITS]: t('common.limits'),
  [COLUMNS.TABLE_SIZES]: t('common.table_sizes'),
  [COLUMNS.MAX_TABLES]: t('common.max_tables'),
  [COLUMNS.IS_ONLINE]: t('common.status'),
  [COLUMNS.PID]: t('common.pid'),
  [COLUMNS.GEO]: t('common.geolocation'),
  [COLUMNS.CHANNELS]: t('common.channels'),
  [COLUMNS.HANDS_PLAYED]: t('common.hands'),
  [COLUMNS.NET_WIN_SUM]: t('common.net_win'),
  [COLUMNS.WIN_RATE_BB]: t('common.bb/100'),
  [COLUMNS.TAGS]: t('common.tags'),
  [COLUMNS.BOT_SETTINGS]: t('common.bot_settings'),
  [COLUMNS.IDENTITY]: t('common.identity'),
  [COLUMNS.AFFILIATE_REFERENCE_NUMBER]: t('common.affiliate'),
  [COLUMNS.LAST_ONLINE]: t('common.last_online'),
  [COLUMNS.PILOT_STATUS]: t('common.pilot'),
  [COLUMNS.LAST_PLAYED]: t('common.last_played'),
  [COLUMNS.USER]: t('common.assign_to'),
  [COLUMNS.CLUB_CODE]: t('common.club_code'),
  [COLUMNS.NEXT_SESSION]: t('common.next_session'),
  [COLUMNS.IDENTITY_BANK_ACCOUNTS]: t('common.bank_accounts'),
  [COLUMNS.IDENTITY_DATE_OF_BIRTH]: t('common.date_of_birth'),
  [COLUMNS.IDENTITY_DOCUMENTS]: t('common.documents'),
  [COLUMNS.IDENTITY_FULL_NAME]: t('common.full_name'),
  [COLUMNS.IDENTITY_GENDER]: t('common.gender'),
  [COLUMNS.IDENTITY_LOCATION]: t('common.location'),
  [COLUMNS.IDENTITY_ONLINE_WALLETS]: t('common.online_wallets'),
  [COLUMNS.IDENTITY_PHONES]: t('common.phones'),
  [COLUMNS.IDENTITY_PHOTOS]: t('common.photos'),
  [COLUMNS.IDENTITY_SOCIAL_NETWORKS]: t('common.social_networks'),
  [COLUMNS.TRAINER_VERSION]: t('common.trainer_version'),
  [COLUMNS.NOTES]: t('common.notes'),
  [COLUMNS.BALANCE_CLUB]: t('common.club_balance'),
  [COLUMNS.BALANCE_LOBBY]: t('common.lobby_balance'),
  [COLUMNS.DISPLAY_NAME]: t('common.nickname'),
  [COLUMNS.EMAIL]: t('common.email'),
  [COLUMNS.VPIP]: t('common.vpip'),
  [COLUMNS.DEALS]: t('common.deals'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB]: t('common.buy_in_bb'),
  [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB]: t('common.rebuy_threshold_bb'),
  [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_BB]: t('common.stop_loss_bb'),
  [COLUMNS.STRATEGY_PROFILE_STOP_WIN_BB]: t('common.stop_win_bb'),
  [COLUMNS.STRATEGY_PROFILE_MAX_SWING_BB]: t('common.max_swing_bb'),
  [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_TABLE_BB]: t('common.stop_loss_by_table_bb'),
  [COLUMNS.STRATEGY_PROFILE_STOP_WIN_TABLE_BB]: t('common.stop_win_by_table_bb'),
  [COLUMNS.STRATEGY_PROFILE_MAX_STACK_SIZE_BB]: t('common.max_stack_bb'),
  [COLUMNS.STRATEGY_PROFILE_EFFECTIVE_STACK_LIMIT_BB]: t('common.eff_stack_bb'),
  [COLUMNS.STRATEGY_PROFILE_TABLE_MIN_HANDS]: t('common.min_hands'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MIN]: t('common.buy_in_min_bb'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MAX]: t('common.buy_in_max_bb'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MIN]: t('common.straddle_tables_buy_in_min_bb'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MAX]: t('common.straddle_tables_buy_in_max_bb'),
  [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB_STRADDLE_TABLE]: t(
    'common.straddle_tables_rebuy_threshold_bb'
  ),
  [COLUMNS.STRATEGY_PROFILE_PLAYER_TYPE]: t('common.player_type'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB]: t('common.buy_in_step_bb'),
  [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB_STRADDLE_TABLE]: t('common.straddle_tables_step_bb'),
  [COLUMNS.STRATEGY_PROFILE_WAIT_FOR_BIG_BLIND]: t('common.wait_for_bb'),
  [COLUMNS.STRATEGY_PROFILE_SEATING_PREFERENCE]: t('common.seat'),
};

export const getChannelsValue = (data: WorkspaceAccountSchema['channels']) => {
  if (!data) throw new Error();

  return data.map((item) => ({
    value: item.id,
    label: item.host,
  }));
};

export const getAccountMetrics = (data: WorkspaceAccountSchema['metrics']) => {
  const { net_win_sum, win_rate_bb, ...rest } = data;

  return {
    net_win_sum: convertCentsToDollars(net_win_sum),
    win_rate_bb: Number(win_rate_bb.toFixed(2)),
    ...rest,
  };
};

export const getSortingLabel = (item: string) => {
  if (item === 'login') return t('common.login');

  const label = Object.entries(COLUMNS).find((el) => {
    let key = el[0];

    if (key.startsWith('STRATEGY_PROFILE')) {
      key = key.replace('STRATEGY_PROFILE', 'STRATEGY');
    }

    return key.toLowerCase() === item;
  });

  if (label) return COLUMNS_LABELS[label[1]];

  return t('common.unknown');
};

export const sortings = [
  'login',
  'club_name',
  'club_code',
  'created_on',
  'modified_on',
  'room',
  'max_tables',
  'pid',
  'hands_played',
  'net_win_sum',
  'win_rate_bb',
  'identity',
  'affiliate_reference_number',
  'last_online',
  'last_played',
  'pilot_status',
  'geo',
  'bot_settings',
  'user',
  'next_session',
  'notes',
  'display_name',
  'strategy_buyin_bb',
  'strategy_rebuy_below_bb',
  'strategy_stop_lose_bb',
  'strategy_stop_win_bb',
  'strategy_max_swing_bb',
  'strategy_stop_lose_table_bb',
  'strategy_stop_win_table_bb',
  'strategy_max_stack_size_bb',
  'strategy_effective_stack_limit_bb',
  'strategy_table_min_hands',
  'strategy_buyin_bb_min',
  'strategy_buyin_bb_max',
  'strategy_buyin_bb_straddle_table_min',
  'strategy_buyin_bb_straddle_table_max',
  'strategy_rebuy_below_bb_straddle_table',
  'strategy_player_type',
  'strategy_buyin_step_bb',
  'strategy_buyin_step_bb_straddle_table',
  'strategy_wait_for_big_blind',
  'strategy_seating_preference',
];

export const getSortingKey = (item: (typeof COLUMNS)[keyof typeof COLUMNS]) => {
  let key = Object.entries(COLUMNS).find((el) => el[1] === item);

  if (key) {
    key = key[0].startsWith('STRATEGY_PROFILE')
      ? [key[0].replace('STRATEGY_PROFILE', 'STRATEGY'), key[1]]
      : key;

    if (sortings.includes(key[0].toLowerCase())) return key[0].toLowerCase();
  }

  return undefined;
};

export const getDefaultValues = (data?: WorkspaceAccountSchema) => ({
  login: data?.login || '',
  password: '',
  room: data ? { value: data.room, label: TRAINER_POKER_ROOM_LABELS[data.room] } : null,
  game_types: data
    ? data.game_types.map((item) => ({
        value: item,
        label: TRAINER_GAME_TYPE_LABELS[item],
      }))
    : [],
  comparative_limits: data
    ? data.comparative_limits.map((item) => ({ value: item, label: TRAINER_LIMITS_LABELS[item] }))
    : [],
  club_ids: data
    ? data.clubs.map((item) => ({ value: item.id, label: item.name, caption: item.code }))
    : [],
  stopped_club_ids: data ? data.stopped_clubs.map((item) => item.id) : [],
  table_sizes: data
    ? data.table_sizes.map((item) => ({
        value: item,
        label: TABLE_SIZE_LABELS[item],
      }))
    : [],
  max_tables: data ? String(data.max_tables) : '',
  geo: data ? getGeolocationAsString(data.geo) : '',
  channel_ids: data ? getChannelsValue(data.channels) : [],
  email: data?.email || '',
  email_password: data?.email_password || '',
  tag_ids: data
    ? data.tags.map((item) => ({
        value: item.id,
        label: item.name,
        icon: <WorkspaceAccountColorCircle color={item.color} />,
      }))
    : [],
  affiliate_reference_number: data?.affiliate_reference_number || '',
  user_id: data?.user ? { value: data.user.id, label: data.user.username } : null,
  bot_settings_ids: data
    ? data.bot_settings.map((item) => ({ value: item.id, label: item.name }))
    : [],
  deal_ids: data ? data.deals.map((item) => ({ value: item.id, label: item.code })) : [],
  identity_id: data?.identity
    ? { value: data.identity.id, label: getIdentityFullName(data.identity) }
    : null,
});

export const getPayload = (values: WorkspaceAccountFields & { nickname_pool?: string }) => {
  const [lat, lng] = values.geo.split(',');

  return {
    login: values.login,
    password: values.password,
    club_ids: values.club_ids.map((item) => item.value),
    stopped_club_ids: values.stopped_club_ids,
    game_types: values.game_types.map((item) => item.value),
    comparative_limits: values.comparative_limits.map((item) => item.value),
    table_sizes: values.table_sizes.map((item) => item.value),
    max_tables: Number(values.max_tables),
    geo: values.geo ? { lat: Number(lat), lng: Number(lng) } : null,
    channel_ids: values.channel_ids.map((item) => item.value),
    email: values.email || null,
    email_password: values.email_password || null,
    tag_ids: values.tag_ids.map((item) => item.value),
    affiliate_reference_number: values.affiliate_reference_number || null,
    user_id: values.user_id?.value || null,
    identity_id: values.identity_id?.value || null,
    bot_settings_ids: values.bot_settings_ids.map((item) => item.value),
    deal_ids: values.deal_ids.map((item) => item.value),
    nickname_pool: values.nickname_pool,
  };
};
