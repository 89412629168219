import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalAccountStrategyProfile } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import queryKeys from '../../config/queryKeys';
import { PLAYER_TYPE_LABELS, SEATING_PREFERENCE_LABELS } from '../account/helpers';

const Label = (props: { tooltip: string; children: string }) => {
  const { tooltip, children } = props;

  return (
    <Tooltip label={tooltip}>
      <div>{children}</div>
    </Tooltip>
  );
};

interface Props {
  account: GlobalAccountSchema;
}

const GlobalAccountDetailStrategyProfileTab = (props: Props) => {
  const { account } = props;

  const { id: accountId } = account;

  const { t } = useTranslation();

  const queryKey = [queryKeys.accountStrategyProfile({ accountId })];
  const queryFn = () => loadGlobalAccountStrategyProfile({ accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return (
    <PageAddon.Fields>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_player_type')}>{t('common.player_type')}</Label>
        }
      >
        {PLAYER_TYPE_LABELS[data.player_type]}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_buy_in_range_bb')}>
            {t('common.buy_in_range_bb')}
          </Label>
        }
      >
        {`${data.buyin_bb_min} — ${data.buyin_bb_max}`}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_buy_in_step_bb')}>
            {t('common.buy_in_step_bb')}
          </Label>
        }
      >
        {data.buyin_step_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_straddle_tables_step_bb')}>
            {t('common.straddle_tables_step_bb')}
          </Label>
        }
      >
        {`${data.buyin_bb_straddle_table_min} — ${data.buyin_bb_straddle_table_max}`}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_straddle_tables_buy_in_step_bb')}>
            {t('common.straddle_tables_buy_in_step_bb')}
          </Label>
        }
      >
        {data.buyin_step_bb_straddle_table}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_rebuy_threshold_bb')}>
            {t('common.rebuy_threshold_bb')}
          </Label>
        }
      >
        {data.rebuy_below_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_straddle_tables_rebuy_threshold_bb')}>
            {t('common.straddle_tables_rebuy_threshold_bb')}
          </Label>
        }
      >
        {data.rebuy_below_bb_straddle_table}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_stop_lose_bb')}>{t('common.stop_loss_bb')}</Label>
        }
      >
        {data.stop_lose_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_stop_win_bb')}>{t('common.stop_win_bb')}</Label>
        }
      >
        {data.stop_win_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_stop_lose_table_bb')}>
            {t('common.stop_loss_by_table_bb')}
          </Label>
        }
      >
        {data.stop_lose_table_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_stop_win_table_bb')}>
            {t('common.stop_win_by_table_bb')}
          </Label>
        }
      >
        {data.stop_win_table_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_max_swing_bb')}>{t('common.max_swing_bb')}</Label>
        }
      >
        {data.max_swing_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_effective_stack_limit_bb')}>
            {t('common.eff_stack_bb')}
          </Label>
        }
      >
        {data.effective_stack_limit_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_max_stack_size_bb')}>
            {t('common.max_stack_bb')}
          </Label>
        }
      >
        {data.max_stack_size_bb}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_table_min_hands')}>{t('common.min_hands')}</Label>
        }
      >
        {data.table_min_hands}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.mtt_max_rebuy')}>{data.mtt_max_rebuy}</PageAddon.Field>
      <PageAddon.Field label={t('common.mtt_addon')}>
        {data.mtt_addon ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.auto_rebuy')}>
        {data.auto_rebuy ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_wait_for_big_blind')}>
            {t('common.wait_for_bb')}
          </Label>
        }
      >
        {data.wait_for_big_blind ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field
        label={
          <Label tooltip={t('sentences.strategy_seating_preference')}>{t('common.seat')}</Label>
        }
      >
        {SEATING_PREFERENCE_LABELS[data.seating_preference]}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalAccountDetailStrategyProfileTab;
