import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { createWorkspaceTag, deleteWorkspaceTag, updateWorkspaceTag } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceTagSchema } from '../../../api/schemas/workspaceTagSchema';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Input from '../../../components/Input';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { required, handleError } from '../../../utils/form';
import WorkspaceSettingsField from '../WorkspaceSettingsField';
import WorkspaceSettingsTagsColor, { presets } from './WorkspaceSettingsTagsColor';

interface Fields {
  name: string;
  color: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data?: WorkspaceTagSchema;
  onRemove?: () => void;
}

const WorkspaceSettingsTagsItem = (props: Props) => {
  const { workspaceId, data, onRemove } = props;

  const { t } = useTranslation();

  const { control, handleSubmit, setError, watch, setValue } = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      name: data?.name || '',
      color: data?.color || presets[0],
    },
  });

  const onSubmit = async (values: Fields) => {
    try {
      const payload = values;

      if (data) {
        updateWorkspaceTag({ workspaceId, tagId: data.id, payload });
      } else {
        await createWorkspaceTag({ workspaceId, payload });

        if (onRemove) onRemove();
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const debounced = useDebouncedCallback(() => handleSubmit(onSubmit)(), 100);

  return (
    <WorkspaceSettingsField>
      <WorkspaceSettingsTagsColor
        value={watch('color')}
        onChange={(value) => {
          setValue('color', value);
          debounced();
        }}
      />
      <Input.Quick
        hint
        name="name"
        control={control}
        rules={{ validate: { required } }}
        autoFocus={!data}
        onSubmit={handleSubmit(onSubmit)}
      />
      <Tooltip label={t('common.delete')}>
        <Confirm
          danger
          confirmText={t('common.delete')}
          onConfirm={async () => {
            if (data) await deleteWorkspaceTag({ workspaceId, tagId: data.id });

            if (onRemove) onRemove();
          }}
        >
          {({ confirming }) => (
            <Button
              danger
              size="small"
              variant="transparent"
              focused={confirming}
              icon={icon('trash', 16)}
            />
          )}
        </Confirm>
      </Tooltip>
    </WorkspaceSettingsField>
  );
};

export default WorkspaceSettingsTagsItem;
