import { ReactElement } from 'react';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { AccountStrategyProfileSchema } from '../../../api/schemas/accountStrategyProfileSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageAddon from '../../../components/PageAddon';
import { useWorkspaceAccountStrategyProfileQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  children: (args: { data: AccountStrategyProfileSchema }) => ReactElement;
}

const WorkspaceAccountStrategyProfileLoader = (props: Props) => {
  const { workspaceId, accountId, children } = props;

  const { data, isPending } = useWorkspaceAccountStrategyProfileQuery({
    workspaceId,
    accountId,
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceAccountStrategyProfileLoader;
