import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { handleError, required, maxLength, minLength, max } from '../../utils/form';
import { numbers } from '../../utils/validations';
import { getDefaultValues, getPayload } from './helpers';
import { Fields } from './types';
import WorkspaceChannelDetailDropdown from './WorkspaceChannelDetailDropdown';
import WorkspaceChannelStatus from './WorkspaceChannelStatus';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: ChannelSchema;
  onClose: () => void;
}

const WorkspaceChannelDetail = (props: Props) => {
  const { workspaceId, data, onClose } = props;

  const { t } = useTranslation();

  const {
    id,
    host,
    ip,
    country,
    avg_latency,
    packet_loss_percent,
    is_used,
    is_deleted,
    modified_on,
    created_on,
  } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  const form = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getDefaultValues(data),
  });

  const { control, handleSubmit, setError } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateWorkspaceChannel({
        workspaceId,
        channelId: data.id,
        payload: getPayload(values),
      });
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const commonProps = { control, onSubmit: handleSubmit(onSubmit) };

  return (
    <PageAddon
      copy
      title={host}
      icons={titleIcons}
      subTitle={[
        ip && <PageAddon.Copy>{ip}</PageAddon.Copy>,
        country && <PageAddon.Copy>{country}</PageAddon.Copy>,
        <WorkspaceChannelStatus data={data} />,
        is_used && <Tooltip label={t('common.used')}>{icon('activity', 16)}</Tooltip>,
      ]}
      onClose={onClose}
    >
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.username')}>
          <Input.Quick
            name="username"
            rules={{ validate: { required, minLength: minLength(4), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.password')}>
          <Input.Quick
            name="password"
            type="password"
            rules={{ validate: { required, minLength: minLength(4) } }}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.host')}>
          <Input.Quick name="host" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.port')}>
          <Input.Quick
            name="port"
            allow={numbers}
            rules={{ validate: { required, max: max(t('common.port'), 65535) } }}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.avg_latency_ms')}>
          {avg_latency ? <PageAddon.Copy>{avg_latency}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.packet_loss_%')}>
          <PageAddon.Copy>{packet_loss_percent}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <WorkspaceChannelDetailDropdown data={data} workspaceId={workspaceId} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceChannelDetail;
